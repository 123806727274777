export const useEmitter = () => {
  const { hooks } = useNuxtApp()

  return {
    emit: hooks.callHook,
    on: hooks.hook,
    onOnce: hooks.hookOnce,
    off: hooks.removeHook,
  }
}
