import type { HookResult } from 'nuxt/schema'

export const EVENT_CHAT_SUPPORT_TOGGLE = 'chat:support:toggle' as const
export const EVENT_MENU_NAV_SIDE_TOGGLE = 'menu:navSide:toggle' as const
export const EVENT_PAGE_FULL_SCREEN_TOGGLE = 'page:fullScreen:toggle' as const

export const EVENTS = [
  EVENT_CHAT_SUPPORT_TOGGLE,
  EVENT_MENU_NAV_SIDE_TOGGLE,
  EVENT_PAGE_FULL_SCREEN_TOGGLE,
] as const

export type TEventKey = (typeof EVENTS)[number]

declare module 'nuxt/app' {
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  interface RuntimeNuxtHooks extends Record<TEventKey, () => HookResult> {}
}

export {}
