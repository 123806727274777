<template>
  <div class="skeleton-base">
    <slot />
  </div>
</template>

<style lang="scss">
  .skeleton-base {
    @apply animate-pulse rounded-md bg-dark-200 dark:bg-dark-800;
  }
</style>
